.calculator-form {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
  padding: 1em;
}

.input-label {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
}

input {
  background-color: rgb(246, 246, 246);
  border-radius: 5px;
  border: none;
}
