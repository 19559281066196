.calculations {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 2em;
  background-color: rgb(101, 64, 235);
  border-radius: 10px;
  padding: 1em;
}
.calculation-values {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 2em;
}
.calculation-labels {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

h2,
h3,
h4 {
  margin: 0;
}

h2,
h4 {
  color: white;
}

h3 {
  font-size: 1.5em;
  color: rgb(69, 188, 148);
}
h4 {
  font-size: 1em;
}
button {
  background-color: rgb(69, 188, 148);
  height: 3em;
  border-radius: 10px;
  color: white;
}
